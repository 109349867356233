<template>
  <div>

    <!-- second app bar -->

    <v-layout wrap class=" hidden-xs-only app px-8">
      <v-flex xs12 sm12 md12>
        <v-app-bar color="white" class="px-0" flat :height="$vuetify.breakpoint.name == 'xs'
          ? '200px'
          : $vuetify.breakpoint.name == 'sm'
            ? '150px'
            : $vuetify.breakpoint.name == 'md'
              ? '100%'
              : '90%'">
         

       
                <v-layout  wrap justify-center pl-lg-2 pl-xl-16 px-0>
                 <v-flex pl-xl-6 pl-lg-4><div  style="text-align: justify; cursor: pointer; font-family: OutfitRegular" @mouseenter="openMainMenu" @mouseleave="debounceCloseMainMenu" class="mx-3 my-1 menu-button">
                    <router-link to="/home" style="text-decoration: none; font-size: 10px;">
                      <v-btn elevation="0" :style="getButtonStyle(mainMenuOpen)">
                        Rubicon Career Development
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </router-link>
                    <v-list style="border-radius: 10px; text-align: left;" v-show="mainMenuOpen" class="menu-content">
                      <v-list-item v-for="(type, i) in types" :key="i" @mouseenter="openChildMenu(type.title)"
                        @mouseleave="debounceCloseChildMenu(type.title)" class="menu-item">
                        <v-list-item-content>
                          <v-list-item-title class="menu-item-title">
                            {{ type.title }}
                            <v-icon right class="menu-item-icon">mdi-chevron-right</v-icon>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list style="border-radius: 10px; left: 258px;" v-show="childMenuOpen[type.title]"
                          class="submenu">
                          <v-list-item v-for="(subType, j) in type.subTypes" :key="j"
                            @mouseenter="openSubMenu(subType.title)" @mouseleave="debounceCloseSubMenu(subType.title)"
                            @click="navigateTo(subType.link)" class="submenu-item">
                            <v-list-item-content>
                              <v-list-item-title class="submenu-item-title">
                                {{ subType.title }}
                                <v-icon v-if="subType.subSubTypes" right
                                  class="submenu-item-icon">mdi-chevron-right</v-icon>
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list style="border-radius: 10px;" v-show="subMenuOpen[subType.title]"
                              class="submenu nested">
                              <v-list-item v-for="(subSubType, k) in subType.subSubTypes" :key="k"
                                @click="navigateTo(subSubType.link)" class="nested-submenu-item">
                                <v-list-item-title class="nested-submenu-item-title">
                                  {{ subSubType.title }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-list-item>
                        </v-list>
                      </v-list-item>
                    </v-list>
                  </div>

                  <v-hover style="text-align: justify; cursor: pointer; font-family: OutfitRegular" v-slot="{ hover }" class="mx-3 my-1 ">
                    <router-link to="/realestate" style="text-decoration: none">
                      <v-btn elevation="0" :style="{
                        borderRadius: '10px',
                        color: hover ? 'white' : 'black',
                        fontFamily: 'OutfitRegular',
                        fontSize: '12px',
                        borderColor: '#1977D7',
                        backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                      }">
                        REAL ESTATE

                      </v-btn>
                    </router-link>
                  </v-hover>


                  <v-hover style="text-align: justify; cursor: pointer; font-family: OutfitRegular" v-slot="{ hover }" class="mx-3 my-1 ">
                    <v-btn elevation="0" :style="{
                      borderRadius: '10px',
                      color: hover ? 'white' : 'black',
                      fontFamily: 'OutfitRegular',
                      fontSize: '12px',
                      borderColor: '#1977D7',
                      backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                    }">
                      BUSINESS STRATEGY

                    </v-btn>
                  </v-hover>


                  <v-hover style="text-align: justify; cursor: pointer; font-family: OutfitRegular" v-slot="{ hover }" class="mx-3 my-1 ">
                    <v-btn elevation="0" :style="{
                      borderRadius: '10px',
                      color: hover ? 'white' : 'black',
                      fontSize: '12px',
                      fontFamily: 'OutfitRegular',
                      borderColor: '#1977D7',
                      backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                    }">
                      HEALTH COUNSELLING SERVICES

                    </v-btn>
                  </v-hover>


                  <v-hover style="text-align: justify; cursor: pointer; font-family: OutfitRegular" v-slot="{ hover }" class="mx-3 my-1 ">
                    <a href="Empower_Steel_Tahiland.pdf" target="_blank">
                      <v-btn elevation="0" :style="{
                        borderRadius: '10px',
                        color: hover ? 'white' : 'black',
                        fontSize: '12px',
                        fontFamily: 'OutfitRegular',
                        borderColor: '#1977D7',
                        backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                      }">
                        GLOBAL STEEL PROCUREMENTS

                      </v-btn>
                    </a>
                  </v-hover>


                  <v-hover style="text-align: justify; cursor: pointer; font-family: OutfitRegular" v-slot="{ hover }" class="mx-3 my-1 ">
                    <v-btn elevation="0" :style="{
                      borderRadius: '10px',
                      color: hover ? 'white' : 'black',
                      fontSize: '12px',
                      fontFamily: 'OutfitRegular',
                      borderColor: '#1977D7',
                      backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                    }">
                      WEBSITES & IT

                    </v-btn>
                  </v-hover>



                 
                    <!-- <v-btn  style="height:35px;font-size:12px" class="mx-3 my-1 ">Real Estate</v-btn>
   
             
                    <v-btn  style="height:35px;font-size:12px" class="mx-3 my-1 ">Business Strategy</v-btn>

                  
        
                  <v-btn  style="height:35px;font-size:12px" class="mx-3 my-1 ">Health Counselling Services</v-btn>

                 

                  <v-btn  style="height:35px;font-size:12px"  class="mx-3 my-1">Global Steel Procurements</v-btn>
 
                  <v-btn   style="height:35px;font-size:12px" class="mx-2 my-1">Website & IT</v-btn>
  -->
                 
                </v-flex>
                  
            
                </v-layout>
           


        </v-app-bar>
      </v-flex>
    </v-layout>


    <!-- small screen -->
    <v-layout wrap class=" hidden-sm-and-up app px-0">
      <v-flex xs12 sm12 md12>
        <v-app-bar color="white" class="px-0" flat height="300px">
          <v-layout wrap justify-center class="app px-0">
            <v-flex xs12 style=" cursor: pointer" text-center align-self-center>
              <v-layout wrap justify-space-between class="headerfont" style="text-align: justify;font-size:14px;">
                <v-flex xs12 pt-2 style="text-align: justify; cursor: pointer" text-center align-self-center>
                  <div class="menu-button">
                    <router-link to="/home" style="text-decoration: none">
                      <v-btn width="270" :style="getButtonStyle(mainMenuOpen)">
                        Rubicon Career Development

                      </v-btn>
                    </router-link>
                  </div>
                </v-flex>
                <v-flex xs12 pt-2 style="text-align: justify; cursor: pointer" text-center align-self-center>
                  <v-hover v-slot="{ hover }">
                    <v-btn width="270" elevation="0" :style="{
                      borderRadius: '10px',
                      color: hover ? 'white' : 'black',
                      fontSize: '12px',
                      fontFamily: 'OutfitRegular',
                      borderColor: '#1977D7',
                      backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                    }">
                      HEALTH COUNSELLING SERVICES
                    </v-btn>
                  </v-hover>
                </v-flex>
                <v-flex xs12 pt-2 style="text-align: justify; cursor: pointer" text-center align-self-center>
                  <v-hover v-slot="{ hover }">
                    <a href="Empower_Steel_Tahiland.pdf" target="_blank">
                      <v-btn width="270" elevation="0" :style="{
                        borderRadius: '10px',
                        color: hover ? 'white' : 'black',
                        fontSize: '13px',
                        fontFamily: 'OutfitRegular',
                        borderColor: '#1977D7',
                        backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                      }">
                        GLOBAL STEEL PROCUREMENTS
                      </v-btn>
                    </a>
                  </v-hover>
                </v-flex>
                <v-flex xs12 pt-2 style="text-align: justify; cursor: pointer" text-center align-self-center>
                  <v-hover v-slot="{ hover }">
                    <v-btn width="270" elevation="0" :style="{
                      borderRadius: '10px',
                      color: hover ? 'white' : 'black',
                      fontFamily: 'OutfitRegular',
                      fontSize: '13px',
                      borderColor: '#1977D7',
                      backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                    }">
                      BUSINESS STRATEGY
                    </v-btn>
                  </v-hover>
                </v-flex>
                <v-flex xs12 pt-2 style="text-align: justify; cursor: pointer" text-center align-self-center>
                  <v-hover v-slot="{ hover }">
                    <v-btn width="270" elevation="0" :style="{
                      borderRadius: '10px',
                      color: hover ? 'white' : 'black',
                      fontSize: '13px',
                      fontFamily: 'OutfitRegular',
                      borderColor: '#1977D7',
                      backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                    }">
                      WEBSITES & It
                    </v-btn>
                  </v-hover>
                </v-flex>
                <v-flex xs12 pt-2 style="text-align: justify; cursor: pointer" text-center align-self-center>
                  <v-hover v-slot="{ hover }">
                    <router-link to="/realestate" style="text-decoration: none">
                      <v-btn width="270" elevation="0" :style="{
                        borderRadius: '10px',
                        color: hover ? 'white' : 'black',
                        fontFamily: 'OutfitRegular',
                        fontSize: '13px',
                        borderColor: '#1977D7',
                        backgroundColor: hover ? '#1E90FF' : '#EFEFEF'
                      }">
                        REAL ESTATE
                      </v-btn>
                    </router-link>
                  </v-hover>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainMenuOpen: false,
      childMenuOpen: {},
      subMenuOpen: {},
      mainMenuTimeout: null,
      childMenuTimeout: {},
      subMenuTimeout: {},
      types: [
        {
          title: 'Learning',
          subTypes: [
            {
              title: 'Languages',
              subSubTypes: [
                { title: 'Rubycon Carmel Course', link: '/carmelcourse' },

              ]
            },
            {
              title: 'Computer Courses',
              subSubTypes: [
                { title: 'Rubycon Zen Course', link: '/zenintro' },

              ]
            },

            { title: 'Medical Courses' },
            { title: 'Accounting Courses' },
          ]
        },
        {
          title: 'Training and Certification',
          subTypes: [
            { title: 'Health & Safety', link: '/onlinecourse' },
          ]
        },
      ],
    };
  },
  methods: {
    openMainMenu() {
      clearTimeout(this.mainMenuTimeout);
      this.mainMenuOpen = true;
    },
    debounceCloseMainMenu() {
      this.mainMenuTimeout = setTimeout(() => {
        this.mainMenuOpen = false;
      }, 200);  // Adjust the delay as necessary
    },
    openChildMenu(title) {
      clearTimeout(this.childMenuTimeout[title]);
      this.$set(this.childMenuOpen, title, true);
    },
    debounceCloseChildMenu(title) {
      this.childMenuTimeout[title] = setTimeout(() => {
        this.$set(this.childMenuOpen, title, false);
      }, 200);  // Adjust the delay as necessary
    },
    openSubMenu(subTitle) {
      clearTimeout(this.subMenuTimeout[subTitle]);
      this.$set(this.subMenuOpen, subTitle, true);
    },
    debounceCloseSubMenu(subTitle) {
      this.subMenuTimeout[subTitle] = setTimeout(() => {
        this.$set(this.subMenuOpen, subTitle, false);
      }, 200);  // Adjust the delay as necessary
    },
    navigateTo(link) {
      this.$router.push(link);
    },
    getButtonStyle(isHovering) {
      return {
        borderRadius: '10px',
        color: isHovering ? 'white' : 'black',
        fontFamily: 'OutfitRegular',
        fontSize: '12px',
        borderColor: '#1977D7',
        backgroundColor: isHovering ? '#1E90FF' : '#EFEFEF',
      };
    }
  }
};
</script>

<style scoped>
.menu-content {
  position: absolute;
  z-index: 200;
  background-color: #1E90FF !important;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  color: white;
}

.submenu {
  position: absolute;

  top: 0;
  background-color: #1E90FF !important;
  border-radius: 10px;


}

.submenu.nested {
  left: 100%;
  top: 0;
}

.menu-button {
  position: relative;
  display: inline-block;
}

/* Default state */
.menu-item .menu-item-title,
.menu-item .menu-item-icon,
.submenu-item .submenu-item-title,
.submenu-item .submenu-item-icon,
.nested-submenu-item .nested-submenu-item-title {
  color: white;
  /* Default text color */
}

/* Hover state for main menu items */
.menu-item:hover {
  background-color: #ffffff;
  /* Change this to your desired color */
}

.menu-item:hover .menu-item-title,
.menu-item:hover .menu-item-icon {
  color: #000000;
  /* Change this to your desired text color on hover */
}


.nested-submenu-item:hover {
  background-color: #ffffff;
}

.submenu-item:hover {
  background-color: #ffffff;
}

/* Hover state for sublist items */
.submenu-item:hover .submenu-item-title,
.submenu-item:hover .submenu-item-icon,
.nested-submenu-item:hover .nested-submenu-item-title {

  color: #000000;
  /* Change this to your desired text color on hover */
}
.v-btn{
  border-radius: 10px;
}
</style>
